import React,{useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import {Accordion, Card, Button, Table, Row, Col} from 'react-bootstrap';
import Select , {components} from 'react-select';
import { FaPlus, FaPencilAlt, FaWindowClose, FaCheck, FaSearch, FaGripVertical, FaTrashAlt, FaCircleNotch } from "react-icons/fa";
import searchImg from '../../assets/search.svg'
import DocReport from './DocReport';
import RemoveDocOrders from '../Modals/RemoveDocOrders';
import PropertyOwnerContact from './../CommonComponents/PropertyOwnerContact';
// import { getContactByClientID, getDefaultCategories, getDocumentByID, updateProjectInfo, getCompletedScopesByClientID, createOwnerContact, getDocOrderIds, removeDocOrderId, addDocOrders, countContactsByClientID, getSearchedContactsByClientID } from '../../graphql/queries';
import { getDefaultCategories, getDocumentByID, updateProjectInfo, getCompletedScopesByClientID, createOwnerContact, getDocOrderIds, removeDocOrderId, addDocOrders, getSearchedContactsByClientID } from '../../graphql/queries';
import { useSelector, useDispatch } from "react-redux";
import ScopeTypes from "../../constants/ScopeTypes";
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import PEdit from '../../assets/p-edit.svg';
import Cancel from '../../assets/cancel.svg';
import Add from '../../assets/add_tax.svg';
import CheckMark from '../../assets/checkmark1.svg';
import * as queryString from "query-string";
import makeAnimated from 'react-select/animated';
import { isEmpty } from '../CommonComponents/helper';
import { FaCaretDown } from "react-icons/fa";
import  secureLocalStorage  from  "react-secure-storage";
const animatedComponents = makeAnimated();


export default function DocDetails(props) {
    const {consoleLog} = require('../commonFunctions.js');
    consoleLog('docDetails props', props);
    const dispatch = useDispatch();
    const [docID, setDocID] = React.useState(props.url_params.doc_id);
    //const [docID, setDocID] = React.useState('');
    const [docDetailsByID, setDocDetailsByID] = React.useState('');
    const [ownerContactId, setOwnerContactId] = React.useState(0);

    // Project Info
    const [project_name, SetProjectName] = React.useState('');
    const [project_address, SetProjectAddress] = React.useState('');
    const [estimate, SetEstimate] = React.useState('');

    const [detailProjectInfo, setDetailProjectInfo] = useState(true);
    const [docName, setDocName] = useState('');
    const [docAddress, setDocAddress] = useState('');
    const [docRef, setDocRef] = useState('');
    const [docClaim, setDocClaim] = useState('');
    const [docStructures, setDocStructures] = useState('');
    const [detailReports, setDetailReports] = React.useState(true);
    const [starterTemplates, setStarterTemplates] = React.useState('');
    const [reportTypes, setReportTypes] = React.useState([]);
    const [stData, setStData] = React.useState(useSelector((state) => state.templateStructure));
    const [searchResults, setSearchResults] = useState([]);
    const [selectedReportTypes, setSelectedReportTypes] = React.useState('');
    const [showAddNewContactLabel, setShowAddNewContactLabel] = React.useState(true);


    const [orderFilters, setOrderFilters] = React.useState({text: "", reportTypes: []});
    const [removeOrderQty, setRemoveOrderQty] = React.useState(false);
    const [showRemoveModal, setShowRemoveModal] = React.useState(false);
    const [removeModalData, setRemoveModalData] = React.useState({});

    const [first_name, setFirstName] = React.useState('');
    const [last_name, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');

    const [ownerName, setOwnerName] = React.useState('');
    const [ownerEmail, setOwnerEmail] = React.useState('');
    const [ownerPhone, setOwnerPhone] = React.useState('');

    const [searchContact, setSearchContact] = React.useState('');

    const [showContactLabel, setShowContactLabel] = React.useState(true);
    const [contacts, setContacts] = React.useState([]);
    const [totalContacts, setTotalContacts] = React.useState(0);
    const [contactsLimit, setContactsLimit] = React.useState(parseInt(process.env.GATSBY_DEFAULT_LIST_SIZE ?? 10));
    const [selectedReports, setSelectedReports] = React.useState([]);
    const [reportdD, setReportDd] = useState([]);
    const [searchReport, setSearchReport] = React.useState("");
    const [docOrders, setDocOrders] = React.useState("");
    const [reportFilterIds, setReportFilterIds] = useState('');
    const [loadMoreReports, setLoadMoreReports] = useState(process.env.GATSBY_DEFAULT_LIST_SIZE ?? 10);
    const [loadMoreReportsSpinner, setLoadMoreReportsSpinner] = useState(false);
    const [loadingMoreContacts, setLoadingMoreContacts] = useState(false);
    const [reportsCount, setReportsCount] = useState(0);
    const [repSearchLoader, setRepSearchLoader] = useState(false);

    React.useState(useSelector((state) => state.templateStructure));

    async function onSearchDefaultTemplates(e){
        consoleLog("onSearchDefaultTemplates: ", e.target.value);
        consoleLog("onSearchDefaultTemplates: ", docOrders);
        setSearchReport(e.target.value);
        setRepSearchLoader(true);

        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        let noOfRecs = process.env.GATSBY_LIST_SIZE_LOAD_MORE ?? 10;
        let reports = [];

        const order_reports = await API.graphql({
            query: getCompletedScopesByClientID,
            variables: {
                client_id: client_id,
                order_id: 0,
                order_ids: docOrders,
                search: e.target.value,
                filter_ids: reportFilterIds,
                limit: noOfRecs
            }
        });
        reports = JSON.parse(JSON.parse(order_reports.data.getCompletedScopesByClientID));
        
        consoleLog('onSearchDefaultTemplates selectedReports>',selectedReports);
        consoleLog("onSearchDefaultTemplates reports:", reports);
        setLoadMoreReports(noOfRecs);
        setSearchResults(reports.data);
        setReportsCount(reports.count);
        if(reports.data.length){
            let new_search_results = [...reports.data];
            consoleLog("onSearchDefaultTemplates reports:", new_search_results);
            let doc_orders = docOrders.split();
            consoleLog("onSearchDefaultTemplates docOrders:", doc_orders);
            consoleLog("onSearchDefaultTemplates docOrders:", doc_orders.length);
            if(doc_orders.length){
                for (let i=0;i<doc_orders.length;i++) {
                    consoleLog("onSearchDefaultTemplates i:", doc_orders[i]);
                    for (let j=0;j<new_search_results.length;j++) {
                        if(doc_orders[i] == new_search_results[j].order_id){
                            new_search_results[j].present_in_doc = true;
                            consoleLog("onSearchDefaultTemplates j:", new_search_results[j]);
                        }
                    }
                }
                setSearchResults(new_search_results);
                consoleLog("onSearchDefaultTemplates reports>", new_search_results);
            }
        }
        setRepSearchLoader(false);
        // setOrderFilters((oldOrderFilters)=>{
        //     let newOrderFilters = {...oldOrderFilters};

        //     newOrderFilters['text'] = e.target.value.toLowerCase();
        //     return newOrderFilters;
        // });
        // setStarterTemplates(e.target.value);
    }

    const CaretDownIcon = () => {
        return <FaCaretDown color={"#000"} />;
      };
      
      const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            <CaretDownIcon />
          </components.DropdownIndicator>
        );
      };
    

    function setContactByID(contactId, allContacts = contacts){
        consoleLog("setContactByID allContacts", allContacts);
        consoleLog("setContactByID contactId", contactId);
        let selectedContact = allContacts.find(contact=>contact.contact_id==contactId);
        if(selectedContact){
            consoleLog("setContactByID selectedContact", selectedContact);
            setOwnerName(selectedContact.first_name + ' ' + selectedContact.last_name);
            setOwnerEmail(selectedContact.email);
            setOwnerPhone(selectedContact.phone);
        }
    }

    useEffect(() => {
        consoleLog("DocDetails docID: ", docID);
        consoleLog("DocDetails ownerContactId: ", ownerContactId);
        getDocDetail();
        fetchReports();
        getSearchedContacts();
        getDefaultReportTypes();
    }, [ownerContactId])

    async function loadMoreContacts(){
        setLoadingMoreContacts(true);
        let newLimit = contactsLimit + parseInt(process.env.GATSBY_LIST_SIZE_LOAD_MORE ?? 10);
        setContactsLimit(newLimit);
        await getSearchedContacts('', newLimit);
        setLoadingMoreContacts(false);
    }

    async function fetchReports() {
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        let noOfRecs = process.env.GATSBY_LIST_SIZE_LOAD_MORE ?? 10;

        const get_doc_orders = await API.graphql({
            query: getDocOrderIds,
            variables: {
                doc_id: docID
            }
        });
        let orders_data = JSON.parse(get_doc_orders.data.getDocOrderIds);
        consoleLog("fetchReports orders_data:", orders_data);

        let doc_orders = [];
        orders_data.forEach((order, i)=>{
            doc_orders.push(order.order_id);
        });

        consoleLog("fetchReports doc_orders:", doc_orders);
        setDocOrders(doc_orders.join());

        const default_starter_templates = await API.graphql({
            query: getCompletedScopesByClientID,
            variables: {
                client_id: client_id,
                order_id: 0,
                order_ids: doc_orders.join(),
                search: searchReport,
                filter_ids: reportFilterIds,
                limit: noOfRecs
            }
        })
        let templates_data = JSON.parse(JSON.parse(default_starter_templates.data.getCompletedScopesByClientID));
        consoleLog("fetchReports templates_data:", templates_data)
        setSearchResults(templates_data.data);
        setReportsCount(templates_data.count);
        if(templates_data.data.length){
            consoleLog("fetchReports", templates_data);
            getDocOrders(templates_data.data, orders_data);
        }
    }
    async function getDefaultReportTypes(){
        var rep_types = [];

        rep_types = ScopeTypes.map(scope=>(
            {
                label: scope.title,
                value: scope.id
            }
        ));
        
        consoleLog("getDefaultReportTypes rep_types: ", rep_types)
        setReportTypes(rep_types);
    }

    const first_name_ref = useRef(0);
    const last_name_ref = useRef(0);
    const email_ref = useRef(0);
    const phone_ref = useRef(0);

    async function createOwnerPropertyContact(){
        if(!first_name){
            consoleLog('createOwnerPropertyContact first_name', first_name);
            first_name_ref.current.setCustomValidity("Please enter First Name.");
            first_name_ref.current.reportValidity();
            return false;
        }
        if(!last_name){
            consoleLog('createOwnerPropertyContact last_name', last_name);
            last_name_ref.current.setCustomValidity('Please enter Last Name.');
            last_name_ref.current.reportValidity();
            return false;
        }
        if(!email){
            consoleLog('createOwnerPropertyContact email', email);
            email_ref.current.setCustomValidity('Please enter Email.');
            email_ref.current.reportValidity();
            return false;
        }
        let reg = email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        consoleLog('createOwnerPropertyContact reg',reg);
        if(!reg){
            email_ref.current.setCustomValidity('Please enter a valid Email.');
            email_ref.current.reportValidity();
            return false;
        }
        if(!phone){
            consoleLog('createOwnerPropertyContact phone', phone);
            phone_ref.current.setCustomValidity('Please enter Phone Number.');
            phone_ref.current.reportValidity();
            return false;
        }
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        const create_owner_contact = await API.graphql({
            query: createOwnerContact,
            variables: {
                customer_id: client_id,
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone: phone
            }
        })
        consoleLog("createOwnerPropertyContact create_owner_contact: ", create_owner_contact);
        let new_contact_id = JSON.parse(create_owner_contact.data.createOwnerContact).insertId;
        consoleLog("createOwnerPropertyContact new_contact_id: ", new_contact_id);
        let contacts_copy = contacts;
        let new_contact_obj = {
            "address_id": 0,
            "name": null,
            "company": null,
            "address": null,
            "address_2": null,
            "city": null,
            "postcode": null,
            "country_id": null,
            "zone_id": null,
            "locked": null,
            "status": null,
            "code": null,
            "contact_id": new_contact_id,
            "customer_id": client_id,
            "type": "property_owner",
            "first_name": first_name,
            "last_name": last_name,
            "email": email,
            "phone": phone,
            "template_id": 0,
            "used_default": 1
        };
        consoleLog("createOwnerPropertyContact contacts_copy: ", contacts_copy);
        contacts_copy.unshift(new_contact_obj);
        consoleLog("createOwnerPropertyContact contacts_copy: ", contacts_copy);
        setContacts(contacts_copy);
        setShowAddNewContactLabel(true);
    }
    async function getDocDetail(){
        consoleLog("getDocDetail, docID: ", docID);
        consoleLog("getDocDetail, stData: ", stData);
        if(typeof stData.team_id != 'undefined' && typeof stData.user_id != 'undefined'){
            consoleLog("getDocDetail", "if>");
            setDocName(stData.name);
            consoleLog("getDocDetail name: ", stData.name);
            setDocAddress(stData.location);
            consoleLog("getDocDetail location: ", stData.location);
            setDocRef(stData.ref_id);
            consoleLog("getDocDetail ref_id: ", stData.ref_id);
            setDocClaim(stData.claim_number);
            consoleLog("getDocDetail claim_number: ", stData.claim_number);
            setDocStructures(stData.structure_count);
            consoleLog("getDocDetail structure_count: ", stData.structure_count);
            setDocDetailsByID(stData);
            setOwnerContactId(stData.owner_contact_id);
            consoleLog("getDocDetail owner_contact_id: ", stData.owner_contact_id);
        }else{
            consoleLog("getDocDetail", "else>");
            const get_doc_details = await API.graphql({
                query: getDocumentByID,
                variables: {
                    doc_id: docID
                }
            })
            consoleLog("getDocDetail result: ", get_doc_details.data.getDocumentByID);
            setDocName(get_doc_details.data.getDocumentByID.name);
            setDocAddress(get_doc_details.data.getDocumentByID.location);
            setDocRef(get_doc_details.data.getDocumentByID.ref_id);
            setDocClaim(get_doc_details.data.getDocumentByID.claim_number);
            setDocStructures(get_doc_details.data.getDocumentByID.structure_count);
            // setSearchResults(get_doc_details.data.getDocumentByIDs);
            setDocDetailsByID(get_doc_details.data.getDocumentByID);
            setOwnerContactId(get_doc_details.data.getDocumentByID.owner_contact_id);
        }
    }
    async function getDocOrders(search_results, orders_data){
        // const get_doc_orders = await API.graphql({
        //     query: getDocOrderIds,
        //     variables: {
        //         doc_id: docID
        //     }
        // });
        
        // let orders_data = JSON.parse(get_doc_orders.data.getDocOrderIds);
        consoleLog("getDocOrders orders_data", orders_data);

        let new_search_results = [...search_results];

        let newSelectedReports = [];

        if(orders_data.length){
            orders_data.forEach((order, i)=>{
                new_search_results.forEach((new_search_result, j)=>{
                    consoleLog("new_search_results", new_search_results)
                    if(order.order_id == new_search_result.order_id){
                        new_search_result.present_in_doc = true;

                        newSelectedReports.push({
                            id: order.order_id,
                            report_type: new_search_result.scope_type_id ,
                            name: new_search_result.name,
                            scope_id: new_search_result.scope_id
                        })
                        return false;
                    }
                });
            });
        }
        consoleLog("getDocOrders new_search_results", new_search_results);
        consoleLog("getDocOrders newSelectedReports", newSelectedReports);
        
        setSelectedReports(newSelectedReports);
        setSearchResults(new_search_results);
    }
    
    async function deleteReportFromDoc(orderData){
        let orderId = orderData.id;
        consoleLog("deleteReportFromDoc docID", parseInt(docID));
        consoleLog("deleteReportFromDoc orderId", orderId);
        consoleLog("deleteReportFromDoc removeOrderQty", removeOrderQty);
        
        setSelectedReports((oldSelectedReports)=>{
            return oldSelectedReports.filter(report=>(
                report.id != orderId
            ));
        })

        setSearchResults((oldSearchResults)=>{
            oldSearchResults.map(result=>{
                if(result.order_id == orderId){
                    delete result.present_in_doc;
                }
                return result;
            });
            return oldSearchResults;
        });
        
        let scopeTypeId = orderData.report_type;
        let scopeIds = [orderData.scope_id];
        let scopeTypes = [scopeTypeId];

        if(scopeTypeId === 1 || scopeTypeId === 4 || scopeTypeId === 12 || scopeTypeId === 17) {
            scopeTypes = [1, 4, 12, 17];
        }

        for(const report of selectedReports) {
            if(scopeTypes.includes(report.report_type) && scopeIds.indexOf(report.scope_id) === -1) {
                scopeIds.push(report.scope_id);
            }
        }
        updateDocOrders(orderId, scopeTypeId, scopeIds, 'delete'); 
    }
    
    async function updateDocOrders(orderId, scopeTypeId, scopeIds, action) {
        props.setReportLoading(true);
        if(scopeIds.length > 0) {
          
            const updateOrders = await API.graphql({
                query: addDocOrders,
                variables: {
                    doc_id: parseInt(docID),
                    doc_type_id: parseInt(props.is_doc),
                    order_id: parseInt(orderId),
                    scope_type_id: parseInt(scopeTypeId),
                    scope_ids: scopeIds.join(','),
                    data: JSON.stringify(stData),
                    token: secureLocalStorage.getItem("user_token"),
                    action: action
                }
            });

            const response = JSON.parse(updateOrders.data.addDocOrders);
            if(response.statusCode === 200) {
                let stCopy = { ...stData };
                stCopy.structure_count = response.structureCount;
                stCopy.structures = response.structures;
                stCopy.quantities = response.quantities; 
                setDocStructures(response.structureCount);
                setStData(stCopy);
                dispatch({ type: "TEMPLATE_STRUCTURE", value: stCopy });
            }
        }
        props.setReportLoading(false);
    }

    async function loadMoreReportsFunc() {
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        let noOfRecs = process.env.GATSBY_LIST_SIZE_LOAD_MORE ?? 10;
        consoleLog('loadMoreReportsFunc noOfRecs>>>', noOfRecs);
        consoleLog('loadMoreReportsFunc loadMoreReports>>>', loadMoreReports);
        consoleLog('loadMoreReportsFunc reportsCount>>>', reportsCount);
        let count = +loadMoreReports + +noOfRecs;
        consoleLog('loadMoreReportsFunc count>>>', count);
        setLoadMoreReports(count);

        if(count < reportsCount){
            setLoadMoreReportsSpinner(true);
            let reports = [];
            let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
            const order_reports = await API.graphql({
                query: getCompletedScopesByClientID,
                variables: {
                    client_id: client_id,
                    order_id: 0,
                    order_ids: docOrders,
                    search: searchReport,
                    filter_ids: reportFilterIds,
                    limit: count
                }
            });
            reports = JSON.parse(JSON.parse(order_reports.data.getCompletedScopesByClientID));
    
            consoleLog("loadMoreReportsFunc reports:", reports);
            setSearchResults(reports.data);
            setReportsCount(reports.count);
            setLoadMoreReportsSpinner(false);
            if(reports.data.length){
                let new_search_results = [...reports.data];
                consoleLog("loadMoreReportsFunc reports:", new_search_results);
                let doc_orders = docOrders.split();
                consoleLog("loadMoreReportsFunc docOrders:", doc_orders);
                consoleLog("loadMoreReportsFunc docOrders:", doc_orders.length);
                if(doc_orders.length){
                    for (let i=0;i<doc_orders.length;i++) {
                        consoleLog("loadMoreReportsFunc i:", doc_orders[i]);
                        for (let j=0;j<new_search_results.length;j++) {
                            if(doc_orders[i] == new_search_results[j].order_id){
                                new_search_results[j].present_in_doc = true;
                                consoleLog("loadMoreReportsFunc j:", new_search_results[j]);
                            }
                        }
                    }
                    setSearchResults(new_search_results);
                    consoleLog("loadMoreReportsFunc reports>", new_search_results);
                }
            }
        }
    }

    async function handleChangeReportType(e) {
        setReportDd(e);
        setRepSearchLoader(true);
        consoleLog("handleChangeReportType selectedReportTypes>", e);
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        let noOfRecs = process.env.GATSBY_LIST_SIZE_LOAD_MORE ?? 11;
        let sid  = e.map(type=>type.value) ?? [];
        consoleLog('handleChangeReportType sid>',sid);
        let filterIds = sid.join();
        consoleLog('handleChangeReportType selectedReports>',selectedReports);
        consoleLog('handleChangeReportType filterIds>',filterIds);
        setSelectedReportTypes(sid);
        setReportFilterIds(filterIds);
        let reports = [];
        
        const order_reports = await API.graphql({
            query: getCompletedScopesByClientID,
            variables: {
                client_id: client_id,
                order_id: 0,
                order_ids: docOrders,
                search: searchReport,
                filter_ids: filterIds,
                limit: noOfRecs,
            }
        });
        reports = JSON.parse(JSON.parse(order_reports.data.getCompletedScopesByClientID));

        consoleLog("handleChangeReportType reports:", reports);
        setLoadMoreReports(noOfRecs);
        setSearchResults(reports.data);
        setReportsCount(reports.count);
        if(reports.data.length){
            let new_search_results = [...reports.data];
            consoleLog("handleChangeReportType reports:", new_search_results);
            let doc_orders = docOrders.split();
            consoleLog("handleChangeReportType docOrders:", doc_orders);
            consoleLog("handleChangeReportType docOrders:", doc_orders.length);
            if(doc_orders.length){
                for (let i=0;i<doc_orders.length;i++) {
                    consoleLog("handleChangeReportType i:", doc_orders[i]);
                    for (let j=0;j<new_search_results.length;j++) {
                        if(doc_orders[i] == new_search_results[j].order_id){
                            new_search_results[j].present_in_doc = true;
                            consoleLog("handleChangeReportType j:", new_search_results[j]);
                        }
                    }
                }
                setSearchResults(new_search_results);
                consoleLog("handleChangeReportType reports>", new_search_results);
            }
        }
        setRepSearchLoader(false);

        // setOrderFilters((oldOrderFilters)=>{
        //     let newOrderFilters = {...oldOrderFilters};

        //     newOrderFilters['reportTypes'] = e.map(type=>type.value) ?? [];
        //     return newOrderFilters;
        // });
        // var selected_reports = [];
        // for(let i=0; i < e.length; i++){
        //     consoleLog("ReportType i : ", e[i]);
        //     selected_reports.push(e[i].value);
        // }
        // selected_reports = selected_reports.toString();
        // consoleLog("selected_reports after: ", selected_reports);

        // setSelectedReportTypes(selected_reports);
    }
    function renderContactSection(){
        consoleLog("renderContactSection", ">")
        showContactLabel ? setShowContactLabel(false) : setShowContactLabel(true);
        (contacts.length > 0) ? setShowAddNewContactLabel(true) : setShowAddNewContactLabel(false);
    }
    function changeDocName(e)
    {
        setDocName(e.target.value);
    }
    function changeDocAddress(e)
    {
        setDocAddress(e.target.value);
    }
    function changeDocRef(e)
    {
        setDocRef(e.target.value);
    }
    function changeDocClaim(e){
        setDocClaim(e.target.value);
    }
    function changeDocStrucutures(e)
    {
        setDocStructures(e.target.value);
    }
    function updateProjectInfoFun()
    {
        consoleLog('updateProjectInfoFun',stData);
        updateDocDetails();
       // alert(docName)
        //variableName=e.target.value;
        setDetailProjectInfo(true)
    }
    function cancelDetailProjectInfo() {
      getDocDetail();
      setDetailProjectInfo(true)
    }
    async function updateDocDetails() {
        const updateDetails = await API.graphql({
            query: updateProjectInfo,
            variables: {
                doc_id: docID,
                name:docName,
                location:docAddress,
                ref_id:docRef,
                claim_number:docClaim,
                structure_count:docStructures
            }
        });
        consoleLog('updateProjectInfoFun', updateDetails);

        let newData = stData;

        newData.name = docName;
        newData.location = docAddress;
        newData.ref_id = docRef;
        newData.claim_number = docClaim;
        newData.structure_count = docStructures;
        consoleLog('updateProjectInfoFun newData',stData);

        setStData(newData);

        dispatch({ type: "TEMPLATE_STRUCTURE", value: newData });
        props.updateDocOnElasticSearch();

    }
    function onDeleteContact(contactId){
        consoleLog("onDeleteContact", ">")
        getSearchedContacts();
        if(contactId === ownerContactId) {
            setOwnerContactId(null);
        }
    }
    function onPropertyOwnerChange(owner_contact_id, owner_detail){
        setOwnerContactId(owner_contact_id);

        owner_detail = JSON.parse(owner_detail);
        
        setOwnerName(owner_detail.name);
        setOwnerEmail(owner_detail.email);
        setOwnerPhone(owner_detail.phone);
    }

    function onSearchFromContact(e){
        let value = e.target.value;
        consoleLog('onSearchFromContact value', value);
        setSearchContact(value);
        getSearchedContacts(value);
    }

    async function getSearchedContacts(value = '', limit = contactsLimit){

        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        const results = await API.graphql({
            query: getSearchedContactsByClientID,
            variables: {
                client_id: client_id,
                type: "property_owner",
                search: value,
                limit: limit,
                selected_contact: ownerContactId
            }
        })
        let data = JSON.parse(results.data.getSearchedContactsByClientID);
        let searched_contacts = data.body?.contacts;
        let total_contacts = data.body?.total_contacts?.[0]?.count;

        consoleLog("onSearchFromContact results", results);
        consoleLog("onSearchFromContact data", data);
        consoleLog("onSearchFromContact searched_contacts", searched_contacts);
        consoleLog("onSearchFromContact total_contacts", total_contacts);

        if(searched_contacts){
            setContacts(searched_contacts);
            setContactByID(ownerContactId, searched_contacts);
        }
        if(total_contacts){
            setTotalContacts(total_contacts);
        }
    }

    function showRemoveModalFunc(order){
        let data = {
            id: order.id,
            name: order.name,
            report_type_name: reportTypes.find(report=>(report.value == order.report_type)).label,
            report_type: order.report_type ,
            scope_id: order.scope_id
        };


        consoleLog("showRemoveModalFunc order", order);
        consoleLog("showRemoveModalFunc data", data);
        setRemoveModalData(data);
        setShowRemoveModal(true);
    }


    return (
    <>
        {removeModalData && showRemoveModal && 
            <RemoveDocOrders
                show={showRemoveModal}
                setModalShow={setShowRemoveModal}
                name={removeModalData.name}
                type={removeModalData.report_type_name}
                updateQty={removeOrderQty}
                setUpdateQty={setRemoveOrderQty}
                removeOrder={()=>{
                    deleteReportFromDoc(removeModalData);
                    setShowRemoveModal(false);
                }}
            />
        }
        {docDetailsByID &&
            <DocDetailSection>

                {/* Project Info Contact Section */}
                <div className="detail_heading_row pt-5 pb-4">
                    <p className="detail_heading mb-4">
                        Project Info
                        { detailProjectInfo
                        ? <a className="s_nav_item ml-3" onClick={() => setDetailProjectInfo(false)}><span><img src={PEdit} style={{marginTop:'-8px',width:"26px" }} alt=""/> </span> </a>
                        : <a className="s_nav_item ml-3" onClick={() => cancelDetailProjectInfo(true)}><span><img src={CheckMark} style={{marginTop:'-8px',width:"26px" }} alt=""/> </span> </a>
                        }
                    </p>

                    <div className="project-info-fields on-always amp-toggle-content is-dormant">
                        <div className="field name">
                            { detailProjectInfo
                            ? <div><span className="detail_heading_label">Project Name:</span>
                                   <span className="detail_title_text ml-3">{docName}</span></div>
                            : <div className="mb-4"><span className="detail_heading_label">Project Name</span><div className="input on-active">
                                   <input type="text" className="setting-input" defaultValue={docName} placeholder="Project Name" onInput={(e)=>changeDocName(e)} autocorrect="off" />
                                   </div></div>
                            }
                        </div>

                        <div className="field location">
                            
                            { detailProjectInfo
                            ?   <div><span className="detail_heading_label">Project Address:</span>
                                    <span className="detail_title_text ml-3">{docAddress}</span></div>
                            :   <div className="mb-4"><span className="detail_heading_label">Project Address</span>
                                    <div className="input on-active">
                                    <input type="text" className="setting-input" value={docAddress} placeholder="Project Location" onInput={(e)=>changeDocAddress(e)} />
                                    </div></div>
                            }
                        </div>

                        { props.is_doc
                        ?
                        <>
                            <div className="field ref-id">
                                { detailProjectInfo
                                ? <div><span className="detail_heading_label">{props.url_params.doc_type_id == 1 ? 'Estimate Number:' : 'Order Number'}</span>
                                    <span className="detail_title_text ml-3" >{docRef}</span></div>
                                : <div className="mb-4"><span className="detail_heading_label">{props.url_params.doc_type_id == 1 ? 'Estimate Number:' : 'Order Number'}</span>
                                    <div className="input on-active">
                                    <input type="text" className="setting-input" value={docRef} placeholder={props.url_params.doc_type_id == 1 ? 'Estimate Number:' : 'Order Number'} onInput={(e)=>changeDocRef(e)} />
                                    </div></div>
                                }
                            </div>

                            <div className="field claim-number">
                                { detailProjectInfo
                                ? <div><span className="detail_heading_label">Claim / Reference Number:</span>
                                    <span className="detail_title_text ml-3">{docClaim}</span></div>
                                : <div className="mb-4"><span className="detail_heading_label">Claim / Reference Number</span>
                                    <div className="input on-active">
                                    <input type="text" className="setting-input" value={docClaim} placeholder="Claim / Reference Number" onInput={(e)=>changeDocClaim(e)} />
                                    </div></div>
                                }
                            </div>
                        </>
                        :
                        <div className="field claim-number">
                            { detailProjectInfo
                            ? <div><span className="detail_heading_label">Order #:</span>
                                <span className="detail_title_text ml-3">{docClaim}</span></div>
                            : <div className="mb-4"><span className="detail_heading_label">Order #</span>
                                <div className="input on-active">
                                <input type="text" className="setting-input" value={docClaim} placeholder="Claim / Reference Number" onInput={(e)=>changeDocClaim(e)} />
                                </div></div>
                            }
                        </div>
                        }
                        <div className="field structure-count">
                            { detailProjectInfo
                            ? <div><span className="detail_heading_label">Structures:</span>
                                <span className="detail_title_text ml-3">{docStructures}</span></div>
                            : <div className="mb-4"><span className="detail_heading_label">Structures</span>
                                <div className="input on-active">
                                <input type="text" className="setting-input" value={docStructures} placeholder="Number of Structures" onInput={(e)=>changeDocStrucutures(e)} />
                                </div></div>
                            }
                        </div>
                        <div className="field mt-3" style={{marginBottom:'45px'}}>
                          { detailProjectInfo
                          ? <div> </div>
                          : <div className='float-right field' style={{alignItems:'center', display:'flex'}}>
                              <a className="s_nav_item mb-2" style={{marginRight:'30px'}} onClick={() => cancelDetailProjectInfo(true)}> <span className="">CANCEL</span> </a>
                              <button className="save-btn" onClick={() => updateProjectInfoFun()}>Save</button>
                            </div>
                          }
                        </div>
                    </div>
                </div>

                {/* Property Owner Contact Section */}
                <div className="detail_heading_row pt-3 pb-5">
                    <p className="detail_heading mb-4">
                        Property Owner
                        { showContactLabel
                        ? <a className="s_nav_item ml-3" onClick={renderContactSection}><span><img src={PEdit} style={{marginTop:'-8px',width:'26px'}} alt=""/></span> </a>
                        : <a className="s_nav_item ml-3" onClick={renderContactSection}><span><img src={CheckMark} style={{marginTop:'-8px',width:'26px'}} alt=""/></span> </a>
                        }
                    </p>

                    <div className="project-info-fields on-always amp-toggle-content is-dormant">
                        <div id="contact-info">
                            <div className="field last_name">
                                <span className="detail_heading_label">Name:</span>
                                <span className="detail_title_text ml-3">{ ownerName }</span>
                            </div>

                            <div className="field location">
                                <span className="detail_heading_label">Email:</span>
                                <span className="detail_title_text ml-3">{ ownerEmail }</span>
                            </div>

                            <div className="field ref-id">
                                <span className="detail_heading_label">Phone:</span>
                                <span className="detail_title_text ml-3" >{ ownerPhone }</span>
                            </div>
                        </div>

                        { !showContactLabel &&
                        <>
                            <div className="tempInputx">
                                <p className="detail_title_text" style={{marginBottom:"5px"}}>Search for an existing contact by company, name, email or phone</p>
                                <div style={{display:"flex", width:"100%", paddingBottom:"0px"}}>
                                    <input type="text" className="setting-input" placeholder='Search any contact info to search' value={searchContact} onChange={onSearchFromContact} />
                                    <a className="srchBtn ml-3"><img src={searchImg} /></a>
                                </div>
                            </div>
                            <Row className="contact_info_box ml-0 mr-0">
                                { showAddNewContactLabel
                                    ? <h5 className="cursor-pointer border-bottom hover-state pb-3 mb-0 w-100" style={{color:'#212E33', fontFamily:'Roboto'}} onClick={() => setShowAddNewContactLabel(false)}><span className="ml-3 mr-2"><FaPlus color={'#F7941C'} size={16}/></span>Add New Contact</h5>
                                    : <h5 className="cursor-pointer border-bottom hover-state pb-3 mb-0 w-100" style={{color:'#212E33', fontFamily:'Roboto'}} onClick={() => setShowAddNewContactLabel(true)}><span className="ml-3 mr-2"><img src={Cancel} style={{width:"22px", height:'auto'}} alt=""/></span>Cancel</h5>
                                }
                                <div className="w-100 contact_info_add_cancel">

                                    { !showAddNewContactLabel
                                    ?
                                        <Row className="m-0">
                                            <Col xs={12} sm={12} md={6}>
                                                <input type="text" placeholder="First Name" className="setting-input" value={first_name} ref={first_name_ref} onInput={(e)=> setFirstName(e.target.value)}/>
                                                <input type="text" placeholder="Last Name" className="setting-input" value={last_name} ref={last_name_ref} onInput={(e)=> setLastName(e.target.value)}/>
                                                <input type="text" placeholder="Email" className="setting-input" value={email} ref={email_ref} onInput={(e)=> setEmail(e.target.value)}/>
                                                <input type="text" placeholder="Phone" className="setting-input" value={phone} ref={phone_ref} onInput={(e)=> setPhone(e.target.value)}/>
                                                <Button className="btn_save_contact mt-4 mb-3" onClick={createOwnerPropertyContact}>SAVE CONTACT </Button>
                                            </Col>
                                            <Col xs={12} sm={12} md={6}></Col>
                                        </Row>
                                    :
                                        contacts.map(filtered_contact => (
                                            <PropertyOwnerContact owner_contact_id={ownerContactId} setOwnerName={setOwnerName} setOwnerEmail={setOwnerEmail} setOwnerPhone={setOwnerPhone} doc_id={docID} contact_info={filtered_contact} onContactDelete={onDeleteContact} onChangePropertyOwner={onPropertyOwnerChange}  updateDocOnElasticSearch={props.updateDocOnElasticSearch}/>
                                        ))
                                    }
                                </div>
                                {
                                    showAddNewContactLabel && (totalContacts > contactsLimit) && <div className="border-top w-100">
                                        <button className="load-more-btn" onClick={loadMoreContacts}>
                                            {loadingMoreContacts
                                            ? <>Loading <span style={{marginLeft: '10px'}}><FaCircleNotch size={20} className="awesome_spinner"/></span></>
                                            : 'Load More'}
                                        </button>
                                    </div>
                                }
                            </Row>
                        </>
                        }

                    </div>
                </div>

                {/* Report Section */}
                <div className="detail_heading_row pt-5 pb-4">
                    <p className="detail_heading mb-4">Reports</p>
                    <p className="detail_title_text">Associating a Scope Report will populate your estimate with real-time data.</p>

                    <Row className="m-0 report-list mb-3">
                        { selectedReports.length ? selectedReports.map(selected_report => {
                            let label = '';
                            let report = reportTypes && reportTypes.find(report=>(report.value == selected_report.report_type));
                            if(!isEmpty(report)) {
                                label = report.label;
                            }
                            return(<p className="w-100">
                                <span className="mr-4" style={{cursor: 'pointer'}} onClick={()=>showRemoveModalFunc(selected_report)}><FaTrashAlt color={"#E1892D"}/></span>
                                <span className="detail_heading_label custom-p-color mr-2">
                                    { label }
                                </span>
                                <span className="detail_title_text custom-p-color">{selected_report.name}</span>
                            </p>);
                        })
                        : ''
                        }
                    </Row>

                    { detailReports
                    ? <a className="s_nav_item mb-2" onClick={() => setDetailReports(false)}><span><img style={{width:"26px", marginRight: '10px' }} src={Add} alt=""/></span> <span className="">Add Report</span> </a>
                    : <a className="s_nav_item mb-2" onClick={() => setDetailReports(true)}><span><img src={CheckMark} style={{width:"26px", marginRight: '10px' }} alt=""/></span> Done </a>
                    }

                    { !detailReports &&
                        <div className="project-info-fields on-always amp-toggle-content is-dormant mt-4">
                            <div className="w-100 d-flex align-items-center justify-content-between">
                                <div className="tempInputx">
                                    <div className="detail_title_text my-2">Search Reports:</div>
                                    <div style={{display:"flex", width:"100%", paddingBottom:"10px"}}>
                                        <input type="text" className="setting-input" value={searchReport} onChange={onSearchDefaultTemplates} placeholder='Search by address / name'/>
                                        <a className="srchBtn">
                                            {repSearchLoader ?
                                                <FaCircleNotch size={23} className="awesome_spinner"/> 
                                            :
                                                <img src={searchImg} />
                                            }
                                        </a>
                                    </div>
                                </div>

                                <div style={{marginTop:'-15px'}} className="tempInputx">
                                 <div  className="detail_title_text my-2">Choose Report Type(s)</div>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={{ DropdownIndicator }}
                                        value={reportdD}
                                        isMulti
                                        options={reportTypes}
                                        placeholder={"Select all that apply"}
                                        onChange={handleChangeReportType}
                                        className="w-100"
                                        styles={{
                                            placeholder: (base) => ({
                                            ...base,
                                            fontSize: "1em",
                                            fontFamily: "Roboto",
                                            color: "#B4BEBF",
                                            }),
                                            control: (provided, state) => ({
                                            ...provided,

                                            boxShadow: "none",
                                            border: "1px solid #B4BEBF",
                                            overflowX: "hidden",
                                            border: state.isFocused && "1px solid #B4BEBF",
                                            }),
                                            menu: (provided, state) => ({
                                            ...provided,
                                            border: state.isFocused && "1px solid #B4BEBF",
                                            border: "1px solid #B4BEBF",
                                            overflowX: "hidden",
                                            }),
                                            option: (provided, state) => ({
                                            ...provided,

                                            overflowX: "hidden",
                                            backgroundColor: state.isFocused && "#F7F9FA",
                                            color: state.isFocused && "#212E33",
                                            borderBottom: "1px solid #B4BEBF",
                                            }),
                                        }}
                                        />
                                </div>
                            </div>


                            <div className="line-item-listing">
                                <div className="item-list line-item-item-list" style={{border: "1px solid #B4BEBF", borderRadius: "5px"}}>
                                    { 
                                        // orderFilters && searchResults.filter(search_result=>(
                                        //     orderFilters.reportTypes.length ? orderFilters.reportTypes.includes(search_result.scope_type_id) : true
                                        // )).filter(search_result=>(
                                        //     search_result.name.toLowerCase().includes(orderFilters.text) || search_result.address_label.toLowerCase().includes(orderFilters.text)
                                        // )).map(search_result => (
                                        searchResults.map(search_result => (
                                            <DocReport
                                            key={search_result.order_id}
                                            doc_id={parseInt(docID)}
                                            search_result={search_result}
                                            setSearchResults={setSearchResults}
                                            setSelectedReports={setSelectedReports}
                                            updateDocOrders={updateDocOrders}
                                            selectedReports={selectedReports}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                            { loadMoreReports < reportsCount &&
                                <button className="load-more-btn" onClick={loadMoreReportsFunc}>{ loadMoreReportsSpinner ? <><span>Loading&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><FaCircleNotch size={20} className="awesome_spinner"/></> : 'Load More'}</button>
                            }
                        </div>
                    }
                </div>

            </DocDetailSection>
        }
    </>
    );
}

const DocDetailSection = styled.div`
.s_nav_item{
    color: #212E33;
    font-family: Rajdhani;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 21px;

    &:hover  {
        color: #F7941C !important; 
      }
}
    .line-item-item-list {
        max-height: 400px;
        overflow-y: scroll !important;
        background: white;
        
        overflow: hidden;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    .css-5b9gaa-menu {
        div:last-child {
           border-bottom: none;
        }
      }
    .custom-p-color{
        color:#E1892D !important;
    }
    .setting-input{
        height:40px;
        width:100%;
        outline:none;
        padding:10px;
        border-radius:4px;
        border:1px solid #B4BEBF;
        color:#212E33;
        font-style: normal;
        font-size:14px;
        margin-top:8px;
    }
    .detail_title_text{
        color: #212E33;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0;
        font-weight:300;
        line-height: 18px;
        margin-bottom:34px;
    }
    .detail_heading_label{
        color: #212E33;
        font-family: Roboto;
        font-size: 14px;
        font-weight:bold;
        letter-spacing: 0;
        line-height: 16px;
    }
    .detail_heading{
        cursor: pointer;
        color: #212E33;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 300;
        letter-spacing: -1.24px;
    }
    .contact_info_box{
        border:1px solid #B4BEBF;
        border-radius: 3px;
    }
    .TickIcon{
        position: absolute;
        top: 10%;
        left: 1%;
    }
    .Overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: red;
    }
    .color-red {
        color: red !important;
    }
    .srchBtn{
        margin: 0.8rem 1rem 1rem 1rem !important;
    }
    .search-result {
        height:68px;
        display:flex;
        align-items:center;
        padding: 0.5rem 1rem;
        cursor: pointer;
        border-bottom: 1px solid #bdbdbd;
    }

    .view_pdf a{
        color: #F7941C;
        font-family: Rajdhani;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.53px;
        line-height: 18px;
        text-align: right;
    }
    .ip{
        width:100%;
        align-items: center;
        background-color: hsl(0, 0%, 100%);
        border-color: #000000 !important;
        border-radius: 0px !important;
        border-style: solid;
        border-width: 1px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 38px;
        outline: 0!important;
        position: relative;
        transition: all 100ms;
        box-sizing: border-box;
        padding:2px 8px;
        margin: 1rem 0px;
        cursor: text !important;
    }
    .btn_save_contact{
        height: 50px;
        width: 50%;
        color: #212E33;
        font-family: Rajdhani;
        background-color: #F7941C !important;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.6px;
        line-height: 21px;
        text-align: center;
        outline:none;
        border:none;
        &:hover {
            background-color: #000 !important;
            color: #fff;
          }
    }
   
    .cursor-pointer{
        cursor: pointer;
    }
    .fs-inherit{
        font-size: inherit;
    }
    .fs-medium{
        font-size: medium;
    }
    .fs-small{
        font-size: small !important;
    }
    .fs-smaller{
        font-size: smaller !important;
    }
    .border-top{
        border-top: 1px solid #bdbdbd;
    }
    .border-bottom{
        border-bottom: 1px solid #bdbdbd;
    }
    .hover-state {
        &:hover  {
            color: #F7941C;
          }
    }
    .doc_lbl{
        display: flex;
    }
    .save-btn {
      height: 44px;
      width: 100px;
      background: #f7941c;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: Rajdhani;
      color: #212e33;
      outline: none;
      border: none;
      font-weight: 600;
      text-transform: uppercase;
      &:hover {
        background-color: #000;
        color: #fff;
      }
    }

    .css-1hb7zxy-IndicatorsContainer {
     cursor : pointer;
    }
    .load-more-btn{
        height: 44px;
        width: 169px;
        border-radius: 3px;
        background-color: #F7941C;
        color: #212E33;
        border: none;
        outline: none;
        font-family: Rajdhani;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.6px;
        line-height: 21px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15px auto;
    }
    .load-more-btn:hover {
        color: white;
        background-color: black;
    }
    .contact_info_add_cancel{
        max-height: 400px;
        overflow-y: auto;
    }
`
